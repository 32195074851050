<template>
  <div class="page-container">
    <HomeNav />
    <HomeFooter />
  </div>
</template>

<script>
import HomeNav from "@/components/HomeNav.vue";
import HomeFooter from "@/components/HomeFooter.vue";

export default {
  name: "HomeLayout",
    components:{HomeNav,HomeFooter}
};
</script>

<style scoped></style>
