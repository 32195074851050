<template>
  <div class="footer-container">
    <div class="footer-center">
      <div class="card">
        <div class="tab1">
          <div class="tit">
            关于学会
          </div>
          <div class="list">
            <div class="item">
              <div class="its">机构加盟</div>
              <div class="its">入驻加盟</div>
              <div class="its">我要入驻</div>
              <div class="its">投资者关系</div>
            </div>
            <div class="item">
              <div class="its">关于我们</div>
              <div class="its">商务合作</div>
              <div class="its">联系我们</div>
              <div class="its">加入我们</div>
            </div>
          </div>
        </div>
        <div class="tab2">
          <div class="tit">
            帮助
          </div>
          <div class="list">
            <div class="item">
              <div class="its">常见问题</div>
              <div class="its">意见反馈</div>
              <div class="its">关于隐私</div>
              <div class="its">版权声明</div>
            </div>
          </div>
        </div>
        <div class="tab3">
          <div class="tit">合作伙伴</div>
          <div class="list">
            <div class="item">
              <img src="../assets/logo.png" alt="" />
              <div class="txt">阿里云</div>
            </div>
            <div class="item">
              <img src="../assets/logo.png" alt="" />
              <div class="txt">腾讯云</div>
            </div>
            <div class="item">
              <img src="../assets/logo.png" alt="" />
              <div class="txt">经品</div>
            </div>
            <div class="item">
              <img src="../assets/logo.png" alt="" />
              <div class="txt">百度</div>
            </div>
            <div class="item">
              <img src="../assets/logo.png" alt="" />
              <div class="txt">惠普</div>
            </div>
          </div>
        </div>
        <div class="tab4">
          <div class="tit">学会公众号</div>
          <img src="../assets/home/icon_img23.png" alt="" />
          <div class="txt">关注获取最新讯息</div>
        </div>
        <div class="tab4">
          <div class="tit">美创汇公众号</div>
          <img src="../assets/home/icon_img24.png" alt="" />
          <div class="txt">关注获取最新讯息</div>
        </div>
      </div>
        <div class="bom">
            <div class="le">
                <img src="../assets/home/icon_img25.png" alt="">
            </div>
            <div class="ri">
                <div class="tip">本站由 阿里云 提供计算与安全服务 违法和不良信息、未成年人保护举报电话：010-58254120 举报邮箱：jubao@36kr.com 网上有害信息举报</div>
<!--                <div class="tcp">-->
<!--                    © 2011~2021 北京多氪信息科技有限公司 | 京ICP备12031756号-6 | 京ICP证150143号 | 京公网安备11010502036099号-->
<!--                </div>-->
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  data() {
    return {};
  }
};
</script>

<style scoped lang="less">
.footer-container {
  background-color: #fff;
  padding: 68px 0 48px;
  .footer-center {
    width: 887px;
    margin: 0 auto;
    .card {
      display: flex;
      padding-bottom: 72px;
      border-bottom: 1px solid rgba(0,0,0,0.12);
      .tab1,
      .tab2 {
        .tit {
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }
        .list {
          display: flex;
          align-items: center;
          .item {
            margin-right: 36px;
            margin-top: 2px;
            .its {
              margin-top: 12px;
              color: rgba(0, 0, 0, 0.45);
              font-size: 12px;
              cursor: pointer;
            }
          }
        }
      }
      .tab2 {
        margin-left: 96px;
      }
      .tab3 {
        margin-left: 96px;
        .tit {
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
        }
        .list {
          display: flex;
          flex-wrap: wrap;
          width: 206px;
          margin-top: 2px;
          .item {
            display: flex;
            align-items: center;
            margin-top: 12px;
            margin-bottom: 14px;
            margin-right: 16px;
            width: 52px;
            img {
              width: 20px;
              height: 12px;
            }
            .txt {
              margin-left: 2px;
              font-size: 10px;
            }
          }
        }
      }
      .tab4 {
        margin-left: 24px;
        .tit {
          font-size: 14px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          text-align: center;
        }
        img {
          width: 86px;
          height: 86px;
          margin-top: 14px;
        }
        .txt {
          margin-top: 2px;
          text-align: center;
          font-size: 10px;
          color: rgba(0, 0, 0, 0.25);
        }
      }
    }
    .bom{
      display: flex;
      align-items: center;
      margin-top: 24px;
      .le{
        margin-right: 84px;
        img{
          width: 42px;
          height: 24px;
        }
      }
      .ri{
        .tip{
          font-size: 10px;
          color: rgba(0,0,0,0.85);
        }
        .tcp{
          font-size: 10px;
          color: rgba(0,0,0,0.45);
          margin-top: 16px;
          text-align: center;
        }
      }
    }
  }
}
</style>
